<template>
  <div>
    <h2 class="tw-px-2 tw-py-4 section-title heading-color">
      {{ title }}
    </h2>
    <ul
      :class="listClass"
    >
      <li
        v-for="post in posts"
        :key="post.id"
        class="tw-h-60 tw-w-full"
      >
        <PostLink
          :post="post"
          :show-card="false"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PostLinkList',

  props: {
    posts: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: 'Recent News',
    },
  },

  computed: {
    listClass() {
      return 'tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-y-4 md:tw-gap-y-0 md:tw-gap-x-4';
    },
  },
};
</script>
