<template>
  <div
    ref="content"
    :class="contentClass"
    @click="handleClick"
  >
    <h3
      v-if="showSummary"
      :class="sectionTitleClass"
    >
      Summary
    </h3>
    <p
      v-if="showSummary"
      v-html="summary"
    />
    <h3
      v-if="isDailySnow && (isUpdatePost || isFullPost)"
      :class="sectionTitleClass"
    >
      {{ isFullPost ? 'Short Term Forecast' : 'Update' }}
    </h3>
    <div
      v-html="content"
    />
    <h3
      v-if="showExtendedForecast"
      :class="sectionTitleClass"
    >
      Extended Forecast
    </h3>
    <div
      v-if="showExtendedForecast"
      v-html="extendedForecast"
    />
    <h3
      v-if="showAnnouncements"
      :class="sectionTitleClass"
    >
      Announcements
    </h3>
    <div
      v-if="showAnnouncements"
      v-html="announcements"
    />
  </div>
</template>

<script>
/* eslint vue/no-v-html: off */

import { showImage } from '@@/utils/CommonUtils';
import { waitForRef } from '@@/utils/Components/ComponentUtils';
import AppViewMixin from '@@/utils/AppViewMixin';

export default {
  name: 'PostContent',

  mixins: [AppViewMixin],

  props: {
    isDailySnow: {
      type: Boolean,
      default: false,
    },
    post: {
      type: Object,
      required: true,
    },
  },

  computed: {
    announcements() {
      return this.post?.content_announcements;
    },

    content() {
      return this.post.content;
    },

    contentClass() {
      return [
        this.$style.content,
        this.inAppView ? this.$style.inAppView : null,
      ];
    },

    extendedForecast() {
      return this.post?.content_extended;
    },

    sectionTitleClass() {
      return 'tw-mt-6 tw-mb-2.5 tw-text-xl tw-font-medium heading-color';
    },

    showAnnouncements() {
      return this.isDailySnow && this.announcements;
    },

    showExtendedForecast() {
      return this.isDailySnow && this.extendedForecast;
    },

    showSummary() {
      return this.isDailySnow && this.summary;
    },

    summary() {
      return this.post?.summary;
    },

    isUpdatePost() {
      return this.post?.post_type && this.post?.post_type === 'u';
    },

    isFullPost() {
      return this.post?.post_type && this.post?.post_type === 'f';
    },
  },

  mounted() {
    this.setUpResponsiveEmbeddedVidoes();
  },

  methods: {
    /**
     * Set up responsive embedded videos using Tailwind classes inspired from the legacy
     * responsive embed styles in Bootstrap v4.
     * SEE: https://gist.github.com/christophengelmayer/0edd4d9b6fdeb6ff551fb80d957fd35d
     * SEE: https://getbootstrap.com/docs/4.6/utilities/embed/
     */
    async setUpResponsiveEmbeddedVidoes() {
      const wrapIframe = (iframe) => {
        const wrapper = document.createElement('div');
        wrapper.classList.add('tw-relative');
        wrapper.style.paddingTop = '56.25%';
        iframe.classList.add('tw-absolute', 'tw-inset-0', 'tw-w-full', 'tw-h-full');
        iframe.parentNode.insertBefore(wrapper, iframe);
        wrapper.appendChild(iframe);
      };

      try {
        await waitForRef(this, 'content');

        const youTubeIframes = this.$refs.content.querySelectorAll('iframe[src*="youtube.com"]');
        const vimeoIframes = this.$refs.content.querySelectorAll('iframe[src*="vimeo.com"]');

        vimeoIframes.forEach((iframe) => wrapIframe(iframe));
        youTubeIframes.forEach((iframe) => wrapIframe(iframe));
      }
      catch (e) {
        // Do nothing. The Vimeo and YouTube iframes will just be displayed as is.
      }
    },

    handleClick(e) {
      // Ignore click events in app view so that the mobile app can display images in a native
      // lightbox.
      if (this.inAppView) {
        return;
      }

      const img = e.target.closest('img');

      if (img) {
        const { src } = img;
        showImage(src);
      }
    },
  },
};
</script>

<style module>
.content p {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 1.25rem;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.content strong {
  font-weight: bold;
}

.content a {
  color: var(--brand-link-color);
}

.content ul,
.content ol,
.content li {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.4;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.content img {
  border-radius: 0.625rem;
  cursor: pointer;
  display: block;
  height: auto !important;
  margin: 15px auto;
  max-width: 100% !important;
  min-height: 75px;
}

.content.inAppView img {
  cursor: auto;
}

@media (min-width: 992px) {
  .content img {
    margin: 20px auto;
  }
}

.content hr {
  border: 0;
  border-top: 1px solid var(--gray-200);
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.content ol,
.content ul {
  margin-bottom: 1rem;
  padding-left: 2.5rem;
}

.content ol {
  list-style: decimal;
}

.content ul {
  list-style: disc;
}
</style>
