<template>
  <NuxtLink
    :class="classNames"
    :to="to"
  >
    <font-awesome-icon
      v-if="isPrevious"
      :class="[iconClass, 'tw-mr-4']"
      icon="chevron-left"
    />
    <slot />
    <font-awesome-icon
      v-if="isNext"
      :class="[iconClass, 'tw-ml-4']"
      icon="chevron-right"
    />
  </NuxtLink>
</template>

<script>
export default {
  name: 'PostNavigationButton',

  props: {
    direction: {
      type: String,
      default: null,
      validator(value) {
        return ['next', 'previous'].includes(value);
      },
    },
    displayBlock: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
    },
    isAction: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classNames() {
      return [
        this.displayBlock ? 'tw-flex' : 'tw-inline-flex',
        'tw-items-center',
        this.isNext ? 'tw-justify-between' : '',
        'tw-h-20 tw-py-4 tw-px-4 tw-rounded-lg',
        'tw-font-semibold tw-text-white tw-no-underline hover:tw-no-underline',
        'tw-transition-all',
        this.isAction ? this.$style.buttonAction : this.$style.button,
      ];
    },

    iconClass() {
      return 'tw-inline-block tw-text-4xl tw-fill-current';
    },

    isNext() {
      return this.direction === 'next';
    },

    isPrevious() {
      return this.direction === 'previous';
    },
  },
};
</script>

<style module>
.button {
  background-color: var(--saturated-navy);
}

.buttonAction {
  background-color: var(--saturated-teal);
}

@media (hover: hover) and (pointer: fine) {
  .button:hover {
    background-color: var(--saturated-blue);
  }

  .buttonAction:hover {
    background-color: var(--saturated-teal-dark-10);
  }
}
</style>
