<template>
  <div>
    <h3 class="tw-px-2 tw-py-4 section-title heading-color">
      {{ sectionTitle }}
    </h3>
    <ResourceHeader
      details-class="tw-mt-1"
      image-size="lg"
      :image-url="imageUrl"
      size="medium"
    >
      <template #title>
        {{ name }}
      </template>
      <template #subtitle>
        {{ title }}
      </template>
      <template #details>
        {{ bio }}
      </template>
    </ResourceHeader>
  </div>
</template>

<script>
export default {
  name: 'PostAuthor',

  props: {
    post: {
      type: Object,
      required: true,
    },
    sectionTitle: {
      type: String,
      default: 'About The Author',
    },
  },

  computed: {
    bio() {
      return this.post.author.bio;
    },

    imageUrl() {
      return this.post.author.photo_url;
    },

    name() {
      return this.post.author.name;
    },

    title() {
      return this.post.author.title;
    },
  },
};
</script>
