<template>
  <ResourceHeader
    image-size="base"
    :image-url="imageUrl"
    size="medium"
  >
    <template #details>
      <span class="tw-block tw-text-base">
        {{ byline }}
      </span>
      <span class="tw-block tw-font-light text-dark-color">
        {{ postTimeAgo }}
      </span>
      <span class="tw-block tw-font-light text-dark-color">
        {{ postDate }}
      </span>
    </template>
  </ResourceHeader>
</template>

<script>
export default {
  name: 'PostHeader',

  props: {
    post: {
      type: Object,
      required: true,
    },
    useUpdatedAt: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    byline() {
      const { name, title } = this.post.author;
      return `By ${name}, ${title}`;
    },

    imageUrl() {
      return this.post.author.photo_url;
    },

    isUpdated() {
      if (!this.useUpdatedAt) {
        return false;
      }

      const postedAt = this.$dayjs(this.post.posted_at);
      const updatedAt = this.$dayjs(this.post.updated_at);
      return updatedAt.isAfter(postedAt);
    },

    postDate() {
      const date = this.isUpdated ? this.post.updated_at : this.post.posted_at;
      return this.$dayjs.utc(date).format('MMMM D, YYYY');
    },

    postTimeAgo() {
      if (this.isUpdated) {
        return `Updated ${this.$dayjs.utc(this.post.updated_at).timeAgo()}`;
      }

      return `Posted ${this.$dayjs.utc(this.post.posted_at).timeAgo()}`;
    },
  },
};
</script>
