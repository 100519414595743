<script setup>
import { formatSnow, safeRound } from '@@/utils/CommonUtils';
import { useLocationsStore } from '@@/stores/Locations';
import { useMetaStore } from '@@/stores/Meta';
import { useUserStore } from '@@/stores/User';
import {
  snowForecast1d,
  snowForecast5d,
  snowForecast10d,
  snowHistory1d,
  snowHistory5d,
  snowHistory10d,
} from '@@/utils/LocationSortFields';
import { useAsyncData } from 'nuxt/app';

const props = defineProps({
  titlePrefix: {
    type: String,
    default: 'Top',
  },
  days: {
    type: Number,
    default: 5,
    validator(value) {
      return [1, 5, 10].includes(value);
    },
  },
  displayInCard: {
    type: Boolean,
    default: false,
  },
  isHistory: {
    type: Boolean,
    default: false,
  },
  limit: {
    type: Number,
    default: 5,
  },
  showCards: {
    type: Boolean,
    default: false,
  },
  cardProps: {
    type: Object,
    default: null,
  },
  compareType: {
    type: String,
    default: 'global',
    validator(value) {
      return ['nearby', 'global', 'regions', 'countries', 'states', 'season-passes', 'daily-reads'].includes(value);
    },
  },
  compareId: {
    type: String,
    default: 'all',
  },
});

const locationsStore = useLocationsStore();
const metaStore = useMetaStore();
const userStore = useUserStore();

const locations = ref([]);

const units = computed(() => userStore.preferences.units);
const hasLocations = computed(() => locations.value.length > 0);

const sortField = computed(() => {
  if (props.isHistory && props.days === 1) {
    return snowHistory1d.field;
  }

  if (props.isHistory && props.days === 5) {
    return snowHistory5d.field;
  }

  if (props.isHistory && props.days === 10) {
    return snowHistory10d.field;
  }

  if (props.days === 1) {
    return snowForecast1d.field;
  }

  if (props.days === 5) {
    return snowForecast5d.field;
  }

  if (props.days === 10) {
    return snowForecast10d.field;
  }

  return null;
});

const title = computed(() => {
  if (props.isHistory && props.days === 1) {
    return `${props.titlePrefix} 24-Hour Deepest Reports`;
  }

  if (props.isHistory) {
    return `${props.titlePrefix} ${props.days}-Day Deepest Reports`;
  }

  if (props.days === 1) {
    return `${props.titlePrefix} 24-Hour Forecasts`;
  }

  return `${props.titlePrefix} ${props.days}-Day Forecasts`;
});

const fetchLocations = async () => {
  let mappedLocations = [];
  const method = props.isHistory ? 'fetchHistorySnowDaily' : 'fetchForecastSnowSummary';

  try {
    const response = await locationsStore[method]({
      compareType: props.compareType,
      compareId: props.compareId,
      days: props.days,
      exclude_zeros: false,
      lightWeight: true,
      limit: props.limit,
      location_type_ids: [
        metaStore.getLocationTypeBySlug('lift-served-ski').id,
      ],
      saveToStore: false,
      sort: sortField.value,
      units: units.value,
    });

    const field = props.isHistory ? 'history_snow_summary' : 'forecast_snow_summary';

    mappedLocations = response.locations.map((location) => {
      const {
        id,
        name,
        image_url,
        slug,
      } = location;

      const { precip_snow } = location[field][0];

      return {
        id,
        name,
        imageUrl: image_url,
        precipSnow: formatSnow(safeRound(precip_snow), units.value, true),
        url: `/location/${slug}/${props.isHistory ? 'snow-report' : 'snow-summary'}`,
      };
    });
  }
  catch (e) {
    // Do nothing, if locations cannot be fetched then just don't render the component.
    console.dir(e);
  }

  return mappedLocations;
};

const updateLocations = async () => {
  locations.value = await fetchLocations();
};

watch(units, updateLocations);

if (props.displayInCard) {
  const key = props.isHistory ? 'compare-top-snow-history' : 'compare-top-snow-forecast';
  const { data } = await useAsyncData(key, fetchLocations);
  locations.value = data.value;
}
else {
  onMounted(updateLocations);
}
</script>

<template>
  <Card
    v-if="displayInCard"
    v-bind="cardProps"
  >
    <template #header>
      <h3 class="tw-mb-3 section-title heading-color">
        {{ title }}
      </h3>
    </template>
    <template #body>
      <ul v-if="hasLocations">
        <li
          v-for="location in locations"
          :key="location.id"
          class="tw-mb-1 md:tw-mb-2 lg:tw-mb-3 last:tw-mb-0"
        >
          <div class="tw-flex tw-justify-between">
            <ResourceHeader
              class="tw-items-center"
              :image-url="location.imageUrl"
              size="small"
            >
              <template #title>
                <NuxtLink
                  class="link-color-brand"
                  :to="location.url"
                >
                  {{ location.name }}
                </NuxtLink>
              </template>
            </ResourceHeader>
            <SnowDataPoint>
              {{ location.precipSnow }}
            </SnowDataPoint>
          </div>
        </li>
      </ul>
      <p
        v-else
        class="tw-h-full tw-flex tw-items-center tw-justify-center"
      >
        <span class="tw-font-bold error-message-color">
          No locations found!
        </span>
      </p>
    </template>
  </Card>
  <transition
    v-else
    enter-active-class="fade-enter-transition"
    enter-from-class="fade-out"
    leave-active-class="fade-leave-transition"
    leave-to-class="fade-out"
  >
    <div v-if="hasLocations">
      <h3 class="tw-mb-3 section-title heading-color">
        {{ title }}
      </h3>
      <ul>
        <li
          v-for="location in locations"
          :key="location.id"
          class="tw-mb-3"
        >
          <Card
            :has-body-padding-small="showCards"
            :no-body-styles="!showCards"
          >
            <template #body>
              <div class="tw-flex tw-justify-between">
                <ResourceHeader
                  class="tw-items-center"
                  :image-url="location.imageUrl"
                  size="small"
                >
                  <template #title>
                    <NuxtLink
                      class="link-color-brand"
                      :to="location.url"
                    >
                      {{ location.name }}
                    </NuxtLink>
                  </template>
                </ResourceHeader>
                <SnowDataPoint>
                  {{ location.precipSnow }}
                </SnowDataPoint>
              </div>
            </template>
          </Card>
        </li>
      </ul>
    </div>
  </transition>
</template>
