<template>
  <h2 :class="titleClass">
    <NuxtLink
      :to="postUrl"
    >
      {{ title }}
    </NuxtLink>
  </h2>
</template>

<script>
export default {
  name: 'PostTitle',

  props: {
    post: {
      type: Object,
      required: true,
    },
    postUrl: {
      type: String,
      required: true,
    },
  },

  computed: {
    title() {
      return this.post.title;
    },

    titleClass() {
      return 'tw-my-3 tw-pt-3 tw-border-t border-color tw-text-2xl tw-font-semibold heading-color';
    },
  },
};
</script>
